<template>
  <div class='contain'>
    <div class='content-pnl'>
        <div class='content-left'>
          <div><img class='content-left-top' src='../../assets/live/login_top_icon.png'></div>
          <div ><img class='content-left-logo' src='../../assets/live/login_logo.png'></div>
        </div>
        <div class='content-right'>
          <div class='content-right-title'>玲珑直播助手</div>
          <div class='content-right-tel'>
            <span class='content-right-span'>手机号:</span>
            <input type='number' :clearable='true'   @input='telInput()'  v-model="tel" placeholder="请输入手机号码" />
          </div>
          <div  class='content-right-code'>
            <span class='content-right-span'>验证码:</span>
            <input type='number'  @input='codeInput()'   v-model="code" placeholder="请输入验证码" />
            <span class='content-right-msg' :class="msg!='获取验证码'?'paddingleft':''" @click='getCode'>{{msg}}</span>
          </div>
          <div>
            <el-button type="primary" @click='home()' :class="['custom-button']">登录</el-button>
          </div>
        </div>
    </div>
  </div>
</template>
<script type='application/javascript'>
import {defineComponent, onMounted,onUnmounted, reactive, toRefs} from "vue";
import {login,getYzm} from '../../api/user';
import {useRoute,useRouter} from "vue-router";
import {Message}  from '../../utils/showMsg';
import {tokenStoreHook} from '../../store/authtoken'
export default defineComponent({
  name: 'Login',
  components: {},
  setup() {
    const { query } = useRoute();
    const router = useRouter();
    const pdata = reactive({
      msg:'获取验证码',
      seconds:60,
      timer:null,
      tel:'',
      code:'',
      userId:'',
    })
    onMounted(() => {
      let tel=localStorage.getItem('logintel');
      if(tel){
        pdata.tel=tel;
      }
    })
    onUnmounted(()=>{
      clearTimeout(pdata.timer);
    })
    const home =async () => {
      if(!pdata.tel){
        return Message.info('请输入手机号')
      }else if(!pdata.code){
        return Message.info('请输入验证码')
      }

      var params={
        phone:pdata.tel,
        code:pdata.code,
        code_remark:'助手登录'
      }

      try{
        const { data, code,msg } =await login(params);
        if(code==200 && data){
          //缓存当前手机号
          localStorage.setItem("logintel",pdata.tel);
          tokenStoreHook().addToken(data.token,data.user_id,data.room_number,data.user_name);

          router.push({path:'/live',query:query})
        }else{
          Message.info(msg);
        }
      }catch (err){
        Message.info(err);
      }

    }

    const getCode =async () => {
      if(pdata.msg!='获取验证码')return;
      let params={
        phone:pdata.tel,
        code_remark:'助手登录'
      }
      const { data, code,msg } =await getYzm(params);
      if(code==200){
        pdata.msg=pdata.seconds+'s';
        timers();
      }else{
        Message.info(msg);
      }
    }
    const telInput = () => {
      if(pdata.tel.toString().length>11){
        pdata.tel=pdata.tel.toString().substring(0,11);
      }
    }
    const codeInput = () => {
      if(pdata.code.toString().length>4){
        pdata.code=pdata.code.toString().substring(0,4);
      }
    }
    const timers = () => {
      pdata.timer=setTimeout(()=>{
        if(pdata.seconds==0){
          clearTimeout(pdata.timer);
          pdata.msg='获取验证码';
          pdata.seconds=60;
          return;
        }
        pdata.seconds=pdata.seconds-1;
        pdata.msg=pdata.seconds+'s';
        timers();
      },1000)
    }
    return{home,getCode, telInput, codeInput, ...toRefs(pdata)};
  }
})
</script>
<style scoped>
.contain{
  background: url("../../assets/live/login_bg.png")no-repeat center;
  height: 100%;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-pnl{
  width: 592px;
  height: 356px;
  border-radius: 9px;
  opacity: 1;
  background: linear-gradient(180deg, #1F1B45 0%, #321D4A 100%);
  display: flex;
}
.content-left{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.content-right{
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-left:35px ;
}
.content-left-logo{
  width: 226px;
  height: 226px;
  margin-bottom: -6px;
}
.content-left-top{
  width: 71px;
  height: 29px;
  margin: 55px 0 0 21px;
}
.content-right-title{
  margin: 50px 0 35px 0;
  font-family: 思源黑体;
  font-size: 22px;
  font-weight: 500;
  color: #FFFFFF;
}
.el-input{
  width: 231px!important;
  border-radius: 6px;
  opacity: 1;
  background: rgba(247, 247, 247, 0.18);
  border: none;
  color: #fff;
}
input{
  width: 231px;
  height: 28px;
  border-radius: 6px;
  opacity: 1;
  background: rgba(247, 247, 247, 0.18);
  border: none;
  padding-left:8px ;
  color: #fff;

}
input:focus {
  outline: none;
}
.content-right-tel{
  margin-bottom: 13px;
}
.content-right-span{
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  display: block;
  margin-bottom: 6px;
}
.content-right-code{
  margin-bottom: 21px;
}
.content-right-msg{
  font-weight: 600;
  font-size: 12px;
  color: #FF9678;
  margin-left: -70px;
  letter-spacing: 0.1em;
  text-align: right;
  width: 70px;
  display: inline-flex;
  justify-content: center;
}
.paddingleft{
  padding-left: 15px;
}
.content-right-msg:hover{
  cursor: pointer;
}
.custom-button{
  width: 84px!important;
  height: 28px!important;
  border-radius: 6px!important;
  background: linear-gradient(180deg, #B0AEFF 0%, #D37AFF 100%)!important;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>