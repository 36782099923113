import { http } from "@/utils/http";

export function login(data) {
  return http.request({
    url: `/api/v1.Liveassistant/Login`,
    method: "post",
    data
  });
}
export function getInformation(data){
  return http.request({
    url:"/api/v1.userlive/basic_information_of_live_broadcast_room_users",
    method:"post",
    data
  })
}
export function getYzm(data) {
  return http.request({
    url: `/api/v1.Register/sendSMSVerificationCode`,
    method: "post",
    data
  });
}






